import { createSelector, createStructuredSelector } from 'reselect';
import {reverse, sortBy} from 'lodash';

const getLikesPart = (state) => state.likes;
const getNewLikes = (state) => getLikesPart(state).newLikes;
const getCollection = (state) => getLikesPart(state).collection;
const getAllLikesIds = (state) => getCollection(state).allIds;
const getLikesById = (state) => getCollection(state).byId;
const getIsLoading = (state) => getLikesPart(state).loading;
const getError = (state) => getLikesPart(state).error;
const getNextPage = (state) => getLikesPart(state).nextPage;
const getMoreItems = (state) => getLikesPart(state).moreItems;
const getShowInfo = state => getLikesPart(state).showInfo;

const getAllLikes = createSelector(getAllLikesIds, getLikesById, (ids, items) =>
  ids.map((id) => items[id])
);

const getSortedLikes = createSelector(
  getAllLikes,
  (items) =>
    reverse(
      sortBy(
        items.map((item) => ({
          ...item,
        })),
        'createdAt'
      )
    )
);

const getLikes = createStructuredSelector({
  likes: getSortedLikes,
  isLoading: getIsLoading,
  moreRecords: getMoreItems,
  nextPage: getNextPage,
});

export default {
  getLikes,
  getIsLoading,
  getError,
  getShowInfo,
  getNewLikes
};
