import types from './types';

const load = () => ({ type: types.load });
const loadSuccess = (likes, moreItems, nextCursor) => ({
  type: types.loadSuccess,
  likes,
  moreItems,
  nextCursor,
});
const loadFailure = (error) => ({ type: types.loadFailure, error });
const loadMore = (nextCursor) => ({ type: types.loadMore, nextCursor });
const loadMoreSuccess = (likes, moreItems, nextCursor) => ({
  type: types.loadMoreSuccess,
  likes,
  moreItems,
  nextCursor,
});
const loadMoreFailure = (error) => ({ type: types.loadMoreFailure, error });
const unlock = (id, likeId, value) => ({
  type: types.unlock,
  id,
  likeId,
  value,
});
const unlockSuccess = (id, userInfo) => ({ type: types.unlockSuccess, id, userInfo });
const unlockFailure = (id, error) => ({ type: types.unlockFailure, id, error });
const setNewLikes = (count) => ({ type: types.setNewLikes, count });
const addNewLikes = () => ({ type: types.addNewLikes });
const unlockInfoShown = () => ({ type: types.unlockInfoShown });

export default {
  load,
  loadSuccess,
  loadFailure,
  loadMore,
  loadMoreSuccess,
  loadMoreFailure,
  unlock,
  unlockSuccess,
  unlockFailure,
  setNewLikes,
  addNewLikes,
  unlockInfoShown,
};
