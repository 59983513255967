const load = 'app/likes/LOAD';
const loadSuccess = 'app/likes/LOADSUCCESS';
const loadFailure = 'app/likes/LOADFAILURE';
const loadMore = 'app/likes/LOADMORE';
const loadMoreSuccess = 'app/likes/LOADMORESUCCESS';
const loadMoreFailure = 'app/likes/LOADMOREFAILURE';
const loadRecent = 'app/likes/LOADRECENT';
const loadRecentSuccess = 'app/likes/LOADRECENTSUCCESS';
const loadRecentFailure = 'app/likes/LOADRECENTFAILURE';
const unlock = 'app/likes/UNLOCK';
const unlockSuccess = 'app/likes/UNLOCKSUCCESS';
const unlockFailure = 'app/likes/UNLOCKFAILURE';
const setLastLoadTime = 'app/likes/lastLoad/SET';
const setNewLikes = 'app/likes/SETNEWLIKES';
const addNewLikes = 'app/likes/ADDNEWLIKES';
const unlockInfoShown = 'app/likes/UNLOCKINFOSHOWN'; 

export default {
  load,
  loadSuccess,
  loadFailure,
  loadMore,
  loadMoreSuccess,
  loadMoreFailure,
  loadRecent,
  loadRecentSuccess,
  loadRecentFailure,
  unlock,
  unlockSuccess,
  unlockFailure,
  setLastLoadTime,
  setNewLikes,
  addNewLikes,
  unlockInfoShown
};
