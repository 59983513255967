import { put, call, takeLatest } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import { GetLikes, UnlockLike } from '../services/webAppEndPoint';
import { paymentFunctions } from '../payment';
import { takeUniq } from '../sagas';

function* loadLikes() {
  try {
    const { likes, moreItems, nextCursor } = yield call(GetLikes);
    yield put(actions.loadSuccess(likes, moreItems, nextCursor));
  } catch (error) {
    yield put(actions.loadFailure(error));
  }
}

function* loadMoreLikes({ nextCursor }) {
  try {
    const { likes, moreItems, nextCursor: nextCursorr } = yield call(GetLikes, nextCursor);
    yield put(actions.loadMoreSuccess(likes, moreItems, nextCursorr));
  } catch (error) {
    yield put(actions.loadMoreFailure(error));
  }
}

function* handleLoadMore() {
  yield takeLatest(types.loadMore, loadMoreLikes);
}

function* unlock({ id, value, likeId }) {
  try {
    const userInfo = yield call(UnlockLike, likeId);
    yield* paymentFunctions.finishTransaction(value);
    // Tracking.track('likeUnlock');
    yield put(actions.unlockSuccess(id, userInfo));
  } catch (error) {
    yield put(actions.unlockFailure(id, error));
  }
}

function* handleLoadLikes() {
  yield takeLatest(types.load, loadLikes);
}

function* handleUnlockLike() {
  yield* takeUniq(types.unlock, unlock, ({ id }) => id);
}

export default {
  handleLoadLikes,
  handleUnlockLike,
  handleLoadMore,
};
